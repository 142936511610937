import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { MuiArrowForward, MuiTypography } from "../styled";

const TextContent = () => {
    return (
        <Box sx={{ width: "50%" }}>
            <Typography
                variant="h3"
                sx={{
                    fontWeight: "bold",
                    lineHeight: 1.3,
                    marginBottom: 2,
                    marginTop: 2,
                    wordSpacing: "-2px",
                }}
            >
                Learn to code Cairo 1.0 on Starknet!
            </Typography>
            <Grid container>
                <Grid item xs={12} mb={1}>
                    <MuiTypography>
                        CairoMummies and Starklings (from OnlyDust) are
                        interactive courses to help you learn the Cairo
                        programming language. Learn to write Starknet smart
                        contracts from the ground up.
                    </MuiTypography>
                </Grid>
                <Grid item xs={12} mt={1} display="flex">
                    <MuiTypography>
                        Our courses are completely browser-based, no setup
                        required! If you get stuck, don't hesitate to ask a
                        question.
                    </MuiTypography>
                </Grid>
                <Grid item xs={12} mt={1} display="flex">
                    <MuiTypography>
                        To show off your progress, you will earn and mint Badge
                        NFTs upon completion of each module!
                    </MuiTypography>
                </Grid>
            </Grid>
        </Box>
    );
};

export default TextContent;
