import HeadComponent from "@js/components/Head";
import Home from "@js/components/Home";

// This is the page for the root url ('/')
const HomePage = () => {
    return (
        <>
            <HeadComponent />
            <Home />
        </>
    );
};

export default HomePage;
