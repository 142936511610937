import Head from "next/head";
import { useRouter } from "next/router";
import { HeadComponentProps } from "./types";

const previewImagePath = "https://triality.org/images/HomeBackground.png";
const defaultTitle = "Triality";
const defaultDescription =
    "Triality | Where Web3 Developers Learn & Share Knowledge";

/**
 * Head component with correct meta tags to show previews when linked in an application like slack/telegram/twitter, etc.
 */
const HeadComponent = (props: HeadComponentProps) => {
    const router = useRouter();
    const tabTitle = props.title ? "Triality | " + props.title : defaultTitle;
    const title = props.title || defaultTitle;
    const description = props.description
        ? props.description
        : defaultDescription;

    return (
        <Head>
            <title>{tabTitle}</title>
            <meta property="og:type" content="website" />
            <meta property="og:site_name" content="Triality" />
            <meta
                property="og:url"
                content={`https://triality.org${router.asPath}`}
            />

            <meta name="twitter:card" content="summary" />
            <meta name="twitter:domain" content="triality.org" />

            <meta property="og:title" content={title} />
            <meta name="twitter:title" content={title} />

            <meta name="description" content={description} />
            <meta property="og:description" content={description} />
            <meta name="twitter:description" content={description} />

            <meta property="og:image" content={previewImagePath} />
            <meta name="twitter:image" content={previewImagePath} />
        </Head>
    );
};

export default HeadComponent;
