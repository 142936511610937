import React from "react";
import { Box, Grid, useTheme } from "@mui/material";
import TextContent from "./TextContent";
import { Button, Container } from "./styled";
import Link from "next/link";

const Home = () => {
    const theme = useTheme();

    return (
        <Container maxWidth={"xl"}>
            <TextContent />
            <Grid container alignItems="baseline">
                <Grid>
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-end"
                    >
                        <img
                            src="/images/MummyLearnMore.svg"
                            alt="mummy walking"
                            height="83px"
                            width="51px"
                        />
                        <Link href="/education" passHref>
                            <Button
                                sx={{
                                    marginTop: "-28px",
                                    border: `2px solid ${theme.palette.primary.main}`,
                                }}
                            >
                                Get started!
                            </Button>
                        </Link>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
};

export default Home;
